// @ts-nocheck
import {TLanguage, TFlagLocaleTuple} from '../types/TLanguage';

export function extractDefaultCountryCode(language: TLanguage): TFlagLocaleTuple {
  if (!language || !language.defaultLocale) {
    return null;
  }

  const {defaultLocale, completeFlagUrls, flagUrls} = language;
  if (completeFlagUrls) {
    const flagUrlEntry = completeFlagUrls
      .filter(([locale]) => locale === defaultLocale)
      .sort(([,,aIndex], [,,bIndex]) => bIndex - aIndex)[0];

    return flagUrlEntry;
  }
  return [defaultLocale, flagUrls[defaultLocale], 0];
}
