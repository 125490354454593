// bi param: step
export enum EStep {
  WELCOME = 'welcome_step',
  MAIN_LANG = 'choose_main_lang',
  SECONDARY_LANG = 'choose_secondary_lang',
  GUIDE = 'final_step',
  GUIDEV2 = 'final_step_v2',
  CHOOSE_TRANSLATION_METHOD = 'choose_translation_method',
  AUTO_TRANSLATION_ERROR = 'auto_translation_error',
  WHATS_NEXT = 'whats_next',
  PARTIAL_SUCCESS = 'PARTIAL_SUCCESS',
}

export enum EOrigin {
  TOP_BAR = 'top_bar',
  BACK_OFFICE = 'back_office',
  APP_MARKET = 'app_market',
  ADD_PANEL = 'add_panel',
}
