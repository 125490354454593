import {createHttpClient} from '../services/http-client';
import {OnBoardingApi} from '../services/onboarding-api';
import {IEditorSDK} from '../types/wix-types';

export async function createLinguistAPI(editorSDK: IEditorSDK) {
  const appInstance = await editorSDK.info.getAppInstance();
  const httpClient = createHttpClient({
    Authorization: appInstance,
  });
  return new OnBoardingApi(httpClient);
}
