// @ts-nocheck
import {findKey} from 'lodash';

export const ARTICLE_LINKS = {
  GUIDE: 'https://support.wix.com/en/article/customizing-your-multilingual-site',
};

export enum envTypes {
  local = 'local',
  test = 'test',
  default = 'default',
  editorx = 'editorx',
}

export const appDefIds: {[s: string]: envTypes} = {
  '14d3fbe6-2340-3cb9-f623-4ad23c09c5a2': envTypes.local,
  'mock-app-def-id': envTypes.test,
};

export const urlMap: {[e in envTypes]: string} = {
  default: 'https://www.wix.com/linguist/index',
  editorx: 'https://www.editorx.com/linguist/index',
  test: 'https://localhost:3000',
  local: 'https://localhost:3000',
};

export const getAppDefIdFromEnv = (env: envTypes): string => {
  return findKey(appDefIds, v => v === env);
};
