import {HttpClient, HttpClientOptions} from '@wix/http-client';

type HttpClientParams = Pick<HttpClientOptions, 'baseURL'> & {
  Authorization?: string;
};

export const createHttpClient = (props: HttpClientParams) => {
  const options: HttpClientOptions = {
    baseURL: props.baseURL,
  };

  if (props.Authorization) {
    options.headers = {
      Authorization: props.Authorization,
    };
  }
  return new HttpClient(options);
};
